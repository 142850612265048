/* @import url(''); */
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Nunito", sans-serif;
    margin: 0 auto;
}

html {
    scroll-behavior: smooth;
  }
  
@layer components {
    .btn-primary {
        @apply flex items-center justify-center cursor-pointer bg-white opacity-100 h-12 py-0 outline-none shadow-btn tracking-1px leading-6 transition duration-100 ease-in hover:no-underline text-base rounded-3xl px-9.5 font-bold;
    }

    .button {
        @apply flex items-center justify-center cursor-pointer opacity-100 py-0 h-14 outline-none shadow-btn tracking-1px leading-6 transition duration-100 ease-in hover:no-underline text-base rounded-3xl px-9.5 font-bold;
    }

    .LinkButton {
        @apply mr-6 flex w-full md:w-80 items-center justify-center outline-none shadow-btn hover:text-liver transition duration-100 ease-in hover:no-underline text-liver hover:animate-shadowPulse md:h-14 h-14 sm:text-xl leading-7 tracking-1px;
    }
}

@layer base {
    h1 {
        @apply text-4xl;
        @apply font-bold;
        @apply leading-10;
        @apply font-Nunito;
    }

    h2 {
        @apply text-2xl;
        @apply font-bold;
        @apply leading-8;
        @apply font-Nunito;
    }

    h3 {
        @apply text-lg-h3;
        @apply font-bold;
        @apply leading-7;
        @apply font-Nunito;
    }

    h4 {
        @apply text-base;
        @apply font-bold;
        @apply leading-6;
        @apply font-Nunito;
    }

    h5 {
        @apply text-xs-h5;
        @apply font-bold;
        @apply leading-4;
        @apply font-Nunito;
    }

    h6 {
        @apply text-xs-h6;
        @apply font-bold;
        @apply leading-3;
        @apply font-Nunito;
    }
}

.sidebar-element {
    width: 279px;
    @apply cursor-pointer h-12 p-3 my-2 text-steel;
}

.sidebar-element-active {
    @apply bg-lavender-mist rounded-l-lg text-jacksons-purple;
}

.drop-down-item {
    @apply flex font-light p-2;
}

.drop-down-checkbox {
    @apply mr-3;
}

.font-dosis {
    font-family: "Dosis", sans-serif;
}

input[type="radio"].radio-large {
    transform: scale(1.5);
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.pink-swan {
    color: #b8b8b8;
}

.bg-pink-swan {
    background-color: #b8b8b8;
}

.white {
    color: #fff;
}

.battleship-grey {
    color: #838383;
}

.bg-battleship-grey {
    background-color: #838383;
}

.raisin-black {
    color: #222222;
}

.bg-raisin-black {
    background-color: #222222;
}

.rounded-4xl {
    border-radius: 32px;
}

.text-iceberg {
    color: #64c4d3;
}

.text-regent-blue {
    color: #64c4d3;
}

.exp-style {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5.68%, #282828 64.58%);
    width: 130px;
    height: 32px;
}

.img-card {
    object-fit: cover;
    width: 130px;
    height: 130px;
}

.therapist-card {
    width: 370px;
}

.fix-img-card {
    object-fit: cover;
    width: 170px;
    height: 170px;
}

.fix-exp-style {
    width: 170px;
    height: 48px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5.68%, #282828 64.58%);
}

.gray_common {
    color: #838383;
}

.mybooking_background {
    background-color: #64c4d3;
}

.mybooking_foldbg {
    background-color: #f8f8f8;
}

.md\:rounded-4xl {
    border-radius: 32px;
}

.bg-light-blue {
    background: #ddf1f4;
}

.doctor-card {
    /* width: 354px; */
    height: 399px;
}

.doctor-image {
    display: table;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    text-align: center;
}

.doctor-image .ImageCard {
    display: table-cell;
    vertical-align: middle;
}

/* Style the back side */
.button-regent-border {
    border: 1px solid #64c4d3;
}

.style-description {
    inline-size: 100%;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.audio-range input::-webkit-slider-thumb {
    background-color: #64c4d3;
    border: solid 6px #64c4d3;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    -webkit-appearance: none;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.news-video video {
    object-fit: cover;
}

/* Scroll bar design across the app */

/* MEDIA QUERY STARTS */
@media (min-width: 768px) {
    .news-video {
        width: 95% !important;
    }

    ::-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #f2f2f2;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
    }

    .user-view ::-webkit-scrollbar-thumb {
        background-color: #64c4d3;
    }

    .md\:text-iceberg {
        color: #64c4d3;
    }

    .active-border-bottom {
        color: #64c4d3 !important;
    }

    .md\:active-border-bottom {
        color: white !important;
        border-bottom: 1px solid white;
    }

    .md\:exp-style {
        width: 170px;
        height: 48px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 5.68%,
            #282828 64.58%
        );
    }

    .fix-exp-style {
        width: 170px;
        height: 48px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 5.68%,
            #282828 64.58%
        );
    }

    .img-card {
        object-fit: cover;
        width: 170px;
        height: 170px;
    }

    .therapist-card {
        width: 480px;
    }

    .md\:rounded-4xl {
        border-radius: 32px;
    }

    .card-shadow {
        background: #ffffff;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
    }

    .blog-image-size {
        width: 720px;
        height: 421px;
    }

    .doctor-card {
        margin-bottom: 5rem !important;
    }

    .date-section {
        width: 100%;
    }

    .booking-slot {
        width: 100%;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 369px) {
    .doctor-detail-card {
        width: 100%;
    }

    /* .menu-bar {
		margin-left: 0rem !important;
	} */
}

@media (min-width: 675px) {
    .doctor-card {
        width: 310px;
    }
}

@media screen and (max-width: 992px) {
    .doctor-card {
        padding-left: 15px;
        width: 100%;
        /* max-width: 318px; */
    }

    .therapistCard {
        max-width: 640px;
    }

    .doctor-profile {
        float: right;
    }
}

.my-doctor-card {
    width: 289.26px;
}

.my-doctor-img {
    /* width :289.26px; */
    height: 169.6px;
}

.my-doctor-img-card {
    width: 200px;
    height: 200px;
}

/* MEDIA QUERY ENDS */

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 2px;
    background-color: #ddf1f4;
    background-image: linear-gradient(
        90deg,
        #64c4d3 var(--percentage),
        transparent var(--percentage)
    );
    outline: none;
}

.slider:hover {
    opacity: 1;
}

.my-audio .slider::-webkit-slider-thumb {
    display: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #64c4d3;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #64c4d3;
    cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.team .slick-dots li button:before {
    font-size: 12px;
}

.team .slick-dots {
    bottom: -40px;
}

.team .slick-dots li {
    margin: 0 2px;
}

.team .slick-dots li.slick-active button:before {
    color: #00c8d4;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.spinner-border {
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right-color: transparent;
}

button.slick-arrow {
    display: none !important;
}

.triangle-down {
    width: 100%;
    height: 44px;
    position: absolute;
    clip-path: polygon(100%, 100% 100%, 50% 0%, 0%);
    top: 99%;
    left: 0;
    z-index: 20;
    /* background-color: #75c594; */
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

@keyframes slide {
    0% {
        transform: translateX(-50);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(50%);
    }
}

.animate-slide {
    animation: slide 5s linear infinite;
}

.y-payBg {
    background: url("/public/assets/images/ypay-bg11.png");
}

/* curvedText */
.curvedText span {
    letter-spacing: 1px;
}
/* center-carousel */
.center-carousel .slick-track {
    padding: 30px 0;
}

.center-carousel .carousel-item {
    transition: transform 0.5s, opacity 0.5s;
}

.center-carousel .slick-center {
    transform: scale(1.1);
    /* Adjust the scale factor as needed */
    opacity: 1;
    z-index: 100;
    position: relative;
    bottom: 40px;
    padding-top: 40px;
}

.center-carousel .slick-center .card {
    box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
    margin-top: 4px;
}

.center-carousel .slick-slide:not(.slick-center) {
    opacity: 0.5;
    transform: scale(1);
}

.center-carousel .card .primQuote {
    display: none;
}

.center-carousel .slick-center .card .secQuote {
    display: none;
}

.center-carousel .slick-center .card .primQuote {
    display: block;
}

.scrollable-container {
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer */
}

.scrollable-container::-webkit-scrollbar {
    display: none !important;
}

/* centers Slider  */

.centers-slider .slick-dots li button:before {
    font-size: 12px;
    color: #fff;
    opacity: 1;
}

.centers-slider .slick-dots {
    bottom: -40px;
}

.centers-slider .slick-dots li {
    margin: 0 2px;
}

.centers-slider .slick-dots li.slick-active button:before {
    color: #ef833a;
}

/* reviews Slider  */

.reviews-slider .slick-dots li button:before {
    font-size: 12px;
    color: #C5DDCC;
    opacity: 1;
}

.reviews-slider .slick-dots {
    bottom: -65px;
}

.reviews-slider .slick-dots li {
    margin: 0 2px;
}

.reviews-slider .slick-dots li.slick-active button:before {
    color: #FFFFFF;
}


.hover-underline {
    position: relative;
    text-decoration: none;
}

.hover-underline a {
    text-decoration: none;
}

  .hover-underline {
	position: relative;
	display: inline-block; 
	color: #FAFAFA; 
  }
  
  .hover-underline::after {
	content: '';
	position: absolute;
	width: 0;
	height: 1px;
	bottom: -2px; 
	left: 0;
	background: #FAFAFA; 
	transition: width 0.3s ease, background-color 0.3s ease;
  }
  
  .hover-underline:hover::after {
	width: 100%;
	background: #FAFAFA; 
  }
 /* Combined Infinity up-down effect */
@keyframes infinityMove {
    0%, 100% {
      transform: translateY(0); 
    }
    25% {
      transform: translateY(-5px); /* Move up */
    }
    50% {
      transform: translateY(5px); /* Move down */
    }
    75% {
      transform: translateY(-5px); /* Move up */
    }
  }
  
  .infinity-move {
    display: inline-block;
    animation: infinityMove 10s ease-in-out infinite; 
  }
  .infinity-move {
    display: inline-block;
    animation: infinityMove 10s ease-in-out 1 forwards; 
  }  